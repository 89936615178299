import React from 'react'
import PropTypes from 'prop-types'
import '../scss/video-banner.scss'
import CTAButton from '../common/CTAButton'
import Word from './Word'
import { HTMLContent } from '../common/Content'

export default function VideoBanner(props) {
  const { videoBanner } = props

  return (
    <React.Fragment>
      <div className="position-relative video-container">
        <div className="w-50 w-m-100 text-banner">
          <div className="text-container">
            <div className="styled-text d-flex">
              {videoBanner.title.map((item) => (
                <Word key={item.text} {...item} />
              ))}
            </div>
            <div className="subtitle">{videoBanner.subtitle}</div>
            <div className="desc">
              <HTMLContent content={videoBanner.description}></HTMLContent>
            </div>
            {(videoBanner.cta || videoBanner.note) && (
              <div className="note-container d-flex align-items-center">
                {videoBanner.cta && (
                  <div className="sign-up-img">
                    <CTAButton {...videoBanner.cta} />
                  </div>
                )}
                {videoBanner.note && (
                  <div className="note">{videoBanner.note}</div>
                )}
              </div>
            )}
          </div>
        </div>
        <div
          className="margin-top-0 w-50 w-m-100 video-parent"
          style={{
            display: 'grid',
            alignItems: 'center'
          }}
        >
          <video
            src={videoBanner.video.publicURL || videoBanner.video.url}
            className="video-player"
            loop={true}
            autoPlay="autoPlay"
            id="vid"
            muted
          ></video>
        </div>
      </div>
    </React.Fragment>
  )
}

VideoBanner.propTypes = {
  videoBanner: PropTypes.object
}
