import React from 'react'
import PropTypes from 'prop-types'
import VideoBanner from '../components/koble/VideoBanner'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import TextAndImage from '../components/koble/TextAndImage'
import Quote from '../components/koble/Quote'

export const HowItWorksTemplate = ({ videoBanner, components, quote }) => {
  return (
    <div>
      <VideoBanner videoBanner={videoBanner} />
      <div className="divider" />
      {components.map((item, index, arr) => (
        <React.Fragment key={index}>
          <TextAndImage {...item} />
          {index < arr.length - 1 && <div className="sub-divider" />}
        </React.Fragment>
      ))}
      <Quote {...quote} />
    </div>
  )
}

HowItWorksTemplate.propTypes = {
  videoBanner: PropTypes.object,
  content: PropTypes.array,
  quote: PropTypes.object
}

const HowItWorksPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <HowItWorksTemplate {...frontmatter} />
    </Layout>
  )
}

HowItWorksPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}

export default HowItWorksPage

export const pageQuery = graphql`
  query HowItWorksTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "how-it-works-page" } }) {
      frontmatter {
        videoBanner {
          title {
            cssClass
            text
          }
          subtitle
          description
          note
          icon {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          video {
            extension
            publicURL

            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
          cta {
            text
            rightIcon
            rightCircle
            link
            type
          }
        }
        components {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH, height: 770)
            }
            publicURL
          }
        }
        quote {
          description
        }
      }
    }
  }
`
